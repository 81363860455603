<template>
  <div>
    <v-data-table 
      class="table-indicadores"
      multi-sort 
      dense 
      :headers="headers" 
      :items="items" 
      :options="{ itemsPerPage: 10 }"
      :headerProps="{ sortByText: 'Ordenar Por' }"
      no-data-text="Nenhum registro disponível" 
      no-results-text="Nenhum registro correspondente encontrado"
      :mobile-breakpoint="10"
    >
      <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
        <v-tooltip bottom v-if="traducaoIndicadores[h.value] != null">
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ h.text }}</span>
          </template>
          <span>{{ traducaoIndicadores[h.value] }}</span>
        </v-tooltip>
        <span v-else>{{ h.text }}</span>
      </template>
      <template v-slot:[`item.empresa`]="{ item }">
        {{ item.empresa.name | parseNumberToFloatBrIgnoringNaN }}
      </template>
      <template v-slot:[`item.qlp`]="{ item }">
        <span :class="classIndicador(item.qlp, item.metas.qlp != undefined ? item.metas.qlp : null)">
          {{ item.qlp | parseNumberToFloatBrIgnoringNaN }}
        </span>

      </template>
      <template v-slot:[`item.pla`]="{ item }">
        <span :class="classIndicador(item.pla, item.metas.pla != undefined ? item.metas.pla : null)">
          {{ item.pla != '-' ? item.pla : '-' | parseNumberToFloatBrIgnoringNaN }}
          {{ item.pla != '-' ? ' %' : '' }}
        </span>
      </template>
      <template v-slot:[`item.pla+e`]="{ item }">
        <span :class="classIndicador(item['pla+e'], item.metas['pla+e'] != undefined ? item.metas['pla+e'] : null)">
          {{ item['pla+e'] != '-' ? item['pla+e'] : '-' | parseNumberToFloatBrIgnoringNaN }}
          {{ item['pla+e'] != '-' ? ' %' : '' }}
        </span>
      </template>
      <template v-slot:[`item.pmea`]="{ item }">
        <span :class="classIndicador(item.pmea, item.metas.pmea != undefined ? item.metas.pmea : null)">
          {{ item.pmea | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
      <template v-slot:[`item.pmea2`]="{ item }">
        <span :class="classIndicador(item.pmea2, item.metas.pmea2 != undefined ? item.metas.pmea2 : null)">
          {{ item.pmea2 | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
      <template v-slot:[`item.pat1`]="{ item }">
        <span :class="classIndicador(item.pat1, item.metas.pat1 != undefined ? item.metas.pat1 : null)">
          {{ item.pat1 | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
      <template v-slot:[`item.pat2`]="{ item }">
        <span :class="classIndicador(item.pat2, item.metas.pat2 != undefined ? item.metas.pat2 : null)">
          {{ item.pat2 | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
      <template v-slot:[`item.pat3`]="{ item }">
        <span :class="classIndicador(item.pat3, item.metas.pat3 != undefined ? item.metas.pat3 : null)">
          {{ item.pat3 | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
      <template v-slot:[`item.pat4`]="{ item }">
        <span :class="classIndicador(item.pat4, item.metas.pat4 != undefined ? item.metas.pat4 : null)">
          {{ item.pat4 | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
      <template v-slot:[`item.metas`]="{ item }">
        <v-btn @click="openMetasDialog(item)" min-width="0" class="px-1" fab icon x-small>
          <v-icon small>
            mdi-information-outline
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>


    <v-dialog v-model="metasDialog" max-width="720">
      <v-card>
        <v-card-title class="text-h5">
          <h4> {{ itemSelected.empresa.name }} - {{ itemSelected.competencia }} </h4>
        </v-card-title>
        <v-card-text>
          <v-simple-table class="table-indicadores" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Indicador</th>
                  <th>Valor</th>
                  <th>Meta</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(valor, indicador) in itemSelected.indicadores" :key="indicador">
                  <v-tooltip bottom v-if="traducaoIndicadores[indicador] != null">
                    <template v-slot:activator="{ on }">
                      <td v-on="on">
                        {{ indicador | toUpperCase }}
                      </td>
                    </template>
                    <span>{{ traducaoIndicadores[indicador] | toUpperCase }}</span>
                  </v-tooltip>
                  <td v-else>
                    {{ indicador | toUpperCase }}
                  </td>
                  <td>
                    {{ valor != null || valor != undefined ? valor : '-' | parseNumberToFloatBrIgnoringNaN}}
                  </td>
                  <td>
                    {{ itemSelected.metas[indicador] === undefined ? '-' : itemSelected.metas[indicador] | parseNumberToFloatBrIgnoringNaN }}
                  </td>
                  <td>
                    <ArrowMetaDashboard 
                    :indicador="valor != null || valor != undefined ? valor : null" 
                    :meta="itemSelected.metas[indicador] != undefined ? itemSelected.metas[indicador] : null" 
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="metasDialog = false">
            Fechar
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import DashboardLnoService from "@/services/DashboardLnoService";
import ArrowMetaDashboard from "./ArrowMetaDashboard.vue";
export default {
  name: "DashboardLnoGeral",
  components: { 'ArrowMetaDashboard': ArrowMetaDashboard },
  props: {
    competenciaDe: {
      type: String,
      required: true,
    },
    competenciaAte: {
      type: String,
      required: true,
    },
    tipoCalculo: {
      type: String,
      required: true,
    },
    activatedTab: {
      type: Number,
      required: true,
    },
    traducaoIndicadores: {
      type: Object,
      default: {}
    },
  },
  data: () => ({
    indicadores: [
      "qlp",
      "pla",
      "plae",
      "pmea",
      "pmea2",
      "pat1",
      "pat2",
      "pat3",
      "pat4"
    ],
    companies: [],
    dados: {},
    metasDialog: false,
    itemSelected: {
      indicadores: [],
      metas: [],
      competencia: '',
      empresa: {
        acronym: '',
        name: ''
      }
    }
  }),
  computed: {
    formattedCompetencia() {
      const [year, month] = this.competencia.split("-");

      return `${month}/${year}`;
    },
    kpis() {
      return this.dados.length > 0 ? Object.keys(this.dados[0]) : [];
    },
    headers() {
      let headers = [

      ];
      this.kpis.forEach(kpi => headers.push({
        text: kpi.toUpperCase(),
        value: kpi.toLowerCase()
      }));
      return headers;
    },
    items() {
      let items = [];
      if (this.dados.length > 0) {
        items = this.dados
      }
      return items;
    }
  },
  mounted() {
    this.getDados();
  },
  methods: {
    getDados() {
      // Apenas atualiza os dados se for a visão selecionada
      if (this.activatedTab !== 0) return;

      this.$emit("updateLoadingDados", true);

      DashboardLnoService.getDadosIndicadoresLnoTodasEmpresas(
        this.competenciaDe,
        this.competenciaAte,
        this.tipoCalculo
      )
        .then(res => {
          this.dados = res;
        })
        .catch(err => {
          console.error(err);
          this.$toast.error('Erro recuperar dados da visão Geral.', '', { position: 'topRight' })
        })
        .finally(() => this.$emit("updateLoadingDados", false));
    },
    getValorMeta(item, indicador) {
      return item.metas?.[this.formattedCompetencia]?.[indicador];
    },
    openMetasDialog(linha) {
      this.itemSelected.indicadores = {
        qlp: linha.qlp,
        pla: linha.pla,
        pmea: linha.pmea,
        pat1: linha.pat1,
        pat2: linha.pat2,
        pat3: linha.pat3,
        pat4: linha.pat4,
      };

      this.itemSelected.metas = linha.metas;
      this.itemSelected.empresa = linha.empresa;
      this.itemSelected.competencia = linha.competencia
      this.metasDialog = true;

    },
    classIndicador(indicador, meta) {
      let classes = [];

      if(indicador != null && meta != null){
        if (indicador > meta) {
          classes.push('success--text font-weight-bold')
        } else if (indicador < meta) {
          classes.push('error--text font-weight-bold')
        }
      }
      return classes
    },
  },
  watch: {
    activatedTab() {
      this.getDados();
    },
    competenciaDe() {
      this.getDados();
    },
    competenciaAte() {
      this.getDados();
    },
    tipoCalculo() {
      this.getDados();
    },
  }
}
</script>

<style>
.table-indicadores {
  margin: 10px 0;
  padding: 10px;
}

.table-indicadores table tbody tr td {
  font-size: 12px;
  padding: 0px 8px !important;
  text-align: end;
}

.table-indicadores table thead tr th {
  font-size: 12px;
  height: 25px;
  text-align: end !important;
  font-weight: 500;
}

.v-card-title-icon {
  position: relative;
  top: -40px;
  transition: .5s ease;
}
</style>